import Button, { SIZES, VARIANTS } from 'common/components/Button';
import I18n from 'common/i18n';
import { Revision } from 'common/types/revision';
import { Source } from 'common/types/source';
import { View } from 'common/types/view';
import UpdateReplaceChooser from 'datasetManagementUI/containers/UpdateReplaceChooserContainer';
import { Params } from 'datasetManagementUI/lib/types';
import { InputSchema, OutputSchema } from 'common/types/dsmapiSchemas';
import React from 'react';
import { Link } from 'react-router';
import classnames from 'classnames';
import { columnMetadataForm, derivedColumnMetadataForm, editSoQL, hrefSource, showBlobPreview, showOutputSchema, sources } from 'datasetManagementUI/links/links';

const t = (k: string) => I18n.t(k, { scope: 'dataset_management_ui.home_pane' });

const buttonClassnames = (isDisabled: boolean) =>
  classnames('btn', 'btn-sm', 'btn-alternate-2', 'btn-inverse', {
    'btn-disabled': isDisabled
  });

interface EditColumnMetadataProps {
  isDisabled: boolean;
  outputSchema: OutputSchema | null;
  params: Params;
}
const EditColumnMetadataButton = ({ isDisabled, outputSchema, params }: EditColumnMetadataProps) => (
  <Link
    className={buttonClassnames(isDisabled)}
    to={outputSchema ? columnMetadataForm(params, outputSchema.id) : ''}
  >
    {t('edit_metadata')}
  </Link>
);

interface EditDerivedColumnMetadataButton {
  params: Params;
}
const EditDerivedColumnMetadataButton = ({ params }: EditDerivedColumnMetadataButton) => (
  <Link className={buttonClassnames(false)} to={derivedColumnMetadataForm(params)}>
    {t('edit_metadata')}
  </Link>
);

interface EditDatasetMetadataButtonProps {
  revision: Revision;
  params: Params;
  isDisabled: boolean;
  openAssetMetadataForm: () => void;
}
const EditDatasetMetadataButton = ({ isDisabled, revision, openAssetMetadataForm }: EditDatasetMetadataButtonProps) => {
  return (
    <Button
      disabled={isDisabled}
      onClick={openAssetMetadataForm}
      size={SIZES.SMALL}
      variant={VARIANTS.ALTERNATE_2}
      inverse
      className='open-modal-button'
    >
      {t('edit_metadata')}
    </Button>
  );
};

interface ReviewDataButtonProps {
  isDisabled: boolean;
  inputSchema: InputSchema | null;
  outputSchema: OutputSchema | null;
  blobId: number | null;
  params: Params;
  isBlobby: boolean;
}

export function reviewDataPath(
  inputSchema: InputSchema | null,
  outputSchema: OutputSchema | null,
  blobId: number | null,
  params: Params,
  isBlobby: boolean
): string | undefined {
  if (outputSchema) {
    if (inputSchema) {
      return showOutputSchema(params, inputSchema.source_id, inputSchema.id, outputSchema.id);
    }
  } else if (isBlobby) {
    return showBlobPreview(params, blobId);
  }
}

const ReviewDataButton = ({
  isDisabled,
  inputSchema,
  outputSchema,
  blobId,
  params,
  isBlobby
}: ReviewDataButtonProps) => (
  <Link
    className={buttonClassnames(isDisabled)}
    to={reviewDataPath(inputSchema, outputSchema, blobId, params, isBlobby) || ''}
  >
    {t('review_data')}
  </Link>
);

interface EditQueryButtonProps {
  view: View;
  isDisabled: boolean;
  params: Params;
}
const EditQueryButton = ({ isDisabled, params }: EditQueryButtonProps) => (
  <Link className={buttonClassnames(isDisabled)} to={editSoQL(params)}>
    {t('edit_query')}
  </Link>
);

interface AddDataButtonProps {
  revision: Revision;
  params: Params;
  isDisabled: boolean;
  href: boolean;
  blob: Omit<Source, 'schemas'> | null;
  editing: boolean;
}
const AddDataButton = ({ revision, params, href, blob, isDisabled, editing }: AddDataButtonProps) => {
  if (href) {
    return (
      <Link className={buttonClassnames(isDisabled)} to={isDisabled ? '' : hrefSource(params)}>
        {t('href_edit_btn')}
      </Link>
    );
  } else if (blob) {
    return (
      <Link className={buttonClassnames(isDisabled)} to={isDisabled ? '' : sources(params)}>
        {t('blob_edit_button')}
      </Link>
    );
  } else if (editing) {
    return <UpdateReplaceChooser params={params} isDisabled={isDisabled} useInverseStyle />;
  } else {
    return (
      <Link className={buttonClassnames(isDisabled)} to={isDisabled ? '' : sources(params)}>
        {t('add_data')}
      </Link>
    );
  }
};

export {
  AddDataButton,
  ReviewDataButton,
  EditColumnMetadataButton,
  EditDerivedColumnMetadataButton,
  EditDatasetMetadataButton,
  EditQueryButton
};
