import ExportModal, { StateProps } from 'common/components/ExportModal';
import { connect } from 'react-redux';
import { AppState, QueryMetaSuccess } from '../../redux/store';
import { querySuccess } from '../../lib/selectors';
import { QueryStatus } from 'common/explore_grid/types';
import { QueryCompilationSucceeded } from 'common/types/compiler';
import { none, some } from 'ts-option';

const mapStateToProps = (state: AppState): StateProps => {
  const queryResult = state.query.queryResult.flatMap<QueryCompilationSucceeded>((qr) => {
    return qr.type == QueryStatus.QUERY_SUCCESS ? some(qr.compiled) : none;
  });
  const queryMeta = querySuccess(state.query.queryResult)
    .map((qr) => qr.meta)
    .flatMap<QueryMetaSuccess>((meta) => {
      return meta.type == QueryStatus.QUERY_META_SUCCESS ? some(meta) : none;
    });
  return {
    query: queryResult,
    meta: queryMeta,
    view: state.view,
    clientContextVariables: state.clientContextInfo.variables
  };
};

export default connect(mapStateToProps)(ExportModal);
