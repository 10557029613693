import * as _ from 'lodash';
import Spinner from 'common/components/Spinner';
import { showErrorToastNow } from 'common/components/ToastNotification/Toastmaster';
import * as Actions from 'common/explore_grid/redux/actions';
import EmbeddedVQE from 'common/explore_grid/EmbeddedVQE';
import {
  lastInChain,
  viewColumnToVQEColumn,
  partialViewColumnToVQEColumn,
  findVQEColumnCaseInsensitive,
  findVQEColumnCaseInsensitiveNA,
  findViewColumnCaseInsensitive
} from 'common/explore_grid/lib/selectors';
import FeatureFlags from 'common/feature_flags';
import { checkStatus, defaultHeaders } from 'common/http';
import I18n from 'common/i18n';
import { OutputColumn, QueryCompilationSucceeded } from 'common/types/compiler';
import { Revision } from 'common/types/revision';
import { ClientContextVariableCreate } from 'common/types/clientContextVariable';
import { soqlRendering, AnalyzedSelectedExpression, isTypedColumnRef, NamedExpr, SoQLType } from 'common/types/soql';
import { View } from 'common/types/view';
import { ViewColumn } from 'common/types/viewColumn';
import { hasUnsaveableComponents, selectionWithProvenanceFromQueryAnalysis } from 'common/explore_grid/lib/soql-helpers';
import { AppState, Dispatch, Params } from 'datasetManagementUI/lib/types';
import { parseDate } from 'datasetManagementUI/lib/parseDate';
import * as ModalActions from 'datasetManagementUI/reduxStuff/actions/modal';
import * as RevisionActions from 'datasetManagementUI/reduxStuff/actions/revisions';
import { updateUndoRedoHistory } from 'datasetManagementUI/reduxStuff/actions/vqeUndoRedoHistory';
import { currentRevision } from 'datasetManagementUI/selectors';
import { toNumber } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { none, Option, option, some } from 'ts-option';
import { viewColumnsToColumnLike, ColumnLike } from 'datasetManagementUI/lib/columnLike';
import './EditSoQLQuery.scss';
import { UndoRedoHistory, VQEColumn } from 'common/explore_grid/redux/store';
import { ForgeBanner, ForgeIcon } from '@tylertech/forge-react';
import OptOutButton from './OptOutButton';
import { revisionBase, derivedColumnMetadataForm, ColumnMetadataType } from 'datasetManagementUI/links/links';
import { getParameters } from 'datasetManagementUI/lib/util';
import { Tab } from 'common/explore_grid/types';
import { TransitionSuccessType, usingSoda3EC } from 'common/explore_grid/lib/feature-flag-helpers';
import { extractSelectionForColumnNames } from 'common/soql/binary-tree';

const t = (k: string) => I18n.t(k, { scope: 'dataset_management_ui' });

interface State {
  compilation: Option<QueryCompilationSucceeded>;
  parentView: Option<View>;
  optOutBannerDismissed: string;
}
export class EditSoQLQuery extends React.Component<Props, State> {
  optOutBannerRef: React.RefObject<Element> = React.createRef();
  state: State = {
    compilation: none,
    parentView: none,
    optOutBannerDismissed: ''
  };

  async componentDidMount() {
    const { view } = this.props;
    try {
      const parentView = await fetch(`/api/views/${view.modifyingViewUid}.json`, {
        credentials: 'same-origin',
        headers: defaultHeaders,
        method: 'GET'
      })
        .then(checkStatus)
        .then((response) => response.json());

      this.setState({ parentView: some(parentView) });
    } catch (e) {
      // something needs to happen here. but this should never happen
      console.error('Failed to fetch the view. This should not happen.');
      console.error(e);
    }

    this.optOutBannerRef.current?.addEventListener('forge-banner-dismissed', () => {
      this.setState({ optOutBannerDismissed: 'dismissed' });
    });
  }

  updatedRevision = (queryString: string, columns: VQEColumn[]): Revision => ({
    ...this.props.revision,
    metadata: {
      ...this.props.revision.metadata,
      columns: columns,
      queryString: queryString
    }
  });

  getQuery = (): string =>
    option(this.props.revision.metadata.queryString).getOrElseValue(this.props.view.queryString!);

  getColumns = (): VQEColumn[] =>
    option(this.props.revision.metadata.columns)
      .map((cols) => {
        return partialViewColumnToVQEColumn(cols);
      })
      .getOrElseValue(viewColumnToVQEColumn(this.props.view.columns));

  getColumn = (an: AnalyzedSelectedExpression): Option<VQEColumn> => {
    return findVQEColumnCaseInsensitive(this.getColumns(), an);
  };
  getColumnNA = (oc: OutputColumn): Option<VQEColumn> => {
    return findVQEColumnCaseInsensitiveNA(this.getColumns(), oc);
  };

  render() {
    const onBackTo = () => {
      browserHistory.push(revisionBase(this.props.params));
    };

    const onQueryRun = (compilationSuccess: QueryCompilationSucceeded, columns: VQEColumn[]) => {
      const newQuery = soqlRendering.unwrap(compilationSuccess.rendering);
      if (newQuery === this.props.revision.metadata.queryString) {
        // nothing has changed, nothing to save
      } else {
        const onError = (e: any) => {
          console.error(e);
          showErrorToastNow(t('edit_query.save_error'));
        };
        try {
          if (hasUnsaveableComponents(some(compilationSuccess))) {
            console.warn(
              "Query was successfully compiled and ran but wasn't valid to be saved, so we didn't."
            );
          } else {
            this.props.updateRevision(this.props.params, this.updatedRevision(newQuery, columns));
          }
        } catch (e) {
          onError(e);
        }
      }
    };

    const { revision, view, undoRedoHistory } = this.props;
    const query = this.getQuery();

    const optOut = (
      <ForgeBanner ref={this.optOutBannerRef} className={this.state.optOutBannerDismissed}>
        <ForgeIcon name="info_outline" className="tyler-icons" slot="icon" />
        <div>{t('edit_query.opt_out')}</div>
        <OptOutButton
          currentView={view}
          discardRevision={() => this.props.deleteRevision(this.props.params)}
          revisionHasChanges={() => {
            // This exercise in frustration is because updated_at can be as little as 3milliseconds greater
            // than created_at at the time of creation. So we're interpreting "less than 1s" to be "no changes
            // have occurred to the revision since creation".
            return (parseDate(revision.updated_at) - parseDate(revision.created_at)) > 1000;
          }}
        />
      </ForgeBanner>
    );

    const shouldShowOptOut = FeatureFlags.value('strict_permissions') && !FeatureFlags.value('remove_banner_from_ec');

    return (
      <div className="edit-soql-query">
        {this.state.parentView.match({
          none: () => <Spinner />,
          some: (parentView) => (
            <React.Fragment>
              {shouldShowOptOut && optOut}
              <EmbeddedVQE
                fourfour={view.modifyingViewUid!}
                onBackTo={onBackTo}
                queryText={some(query)}
                parameters={getParameters(this.props.revision.metadata.clientContext, this.props.view.id)}
                revisionSeq={this.props.revision.revision_seq}
                revisionFourfour={this.props.revision.fourfour}
                view={this.props.view}
                columns={this.getColumns()}
                parentView={some(parentView)}
                onQueryRun={onQueryRun}
                undoRedoHistory={undoRedoHistory}
                contextualEventHandlers={{
                  editColumnMetadata: (metadataType: ColumnMetadataType, column: Partial<ViewColumn>) => {
                    browserHistory.push(
                      derivedColumnMetadataForm(this.props.params, column.fieldName, metadataType)
                    );
                  },
                  getColumn: usingSoda3EC() ? this.getColumnNA : this.getColumn,
                  formatColumn: (column: VQEColumn, columnUpdated: (updatedColumn?: VQEColumn) => void) => {
                    const columnLike = viewColumnsToColumnLike([column])[0];
                    this.props.openFormatColumn(this.props.params, columnLike, columnUpdated);
                  },
                  handleColumnWidthChange: (column: VQEColumn, changeVQEColumnStateCB: () => void) => {
                    const newRevision = {
                      ...this.props.revision, metadata: {
                        ...this.props.revision.metadata,
                        columns: this.props.revision.metadata.columns?.map((col) => {
                          if (col.fieldName === column.fieldName) {
                            return {
                              ...col, width: column.width
                            };
                          } else {
                            return col;
                          }
                        })
                      }
                    };
                    this.props.updateRevision(this.props.params, newRevision)
                      .then(changeVQEColumnStateCB);
                  },
                  resolveColumnMetadata: (success: TransitionSuccessType) => {
                    return success.fold(
                      compSuccess => {
                        const selections = extractSelectionForColumnNames(compSuccess.analyzed);
                        const currentView = this.props.view;
                        const inUseDisplayNames = this.getColumns()
                          .map((col) => option(col.name))
                          .filter((name) => name.nonEmpty)
                          .map((name) => name.get.toLocaleLowerCase());

                        const dedupeDisplayName = (
                          selection: AnalyzedSelectedExpression,
                          proposedDisplayName: string,
                          usedNames: string[]
                        ): string => {
                          const selectionIsNewlyAdded = !this.getColumns()
                            .map((col) => col.fieldName)
                            .includes(selection.name);
                          let displayName = proposedDisplayName;
                          if (
                            selectionIsNewlyAdded &&
                            usedNames.includes(proposedDisplayName.toLocaleLowerCase())
                          ) {
                            if (!usedNames.includes(selection.name)) {
                              displayName = selection.name;
                            } else {
                              let i = 2;
                              while (usedNames.includes(displayName.toLocaleLowerCase())) {
                                displayName = `${proposedDisplayName} ${i}`;
                                i++;
                              }
                            }
                          }
                          return displayName;
                        };

                        const newColumns = selections.map((selection: AnalyzedSelectedExpression, i: number) => {
                          const expr = selection.expr;
                          let sourceColumn: Option<ViewColumn> = none;

                          if (isTypedColumnRef(expr)) {
                            const sourceView: View = (() => {
                              const viewsMap = compSuccess.views;
                              if (expr.qualifier) {
                                if (_.has(compSuccess.tableAliases.realTables, expr.qualifier)) {
                                  return viewsMap[compSuccess.tableAliases.realTables[expr.qualifier]]; // joined table has an alias
                                } else if (_.includes(compSuccess.tableAliases.virtualTables, expr.qualifier)) {
                                  return currentView; // join subselect must have an alias
                                                      // core knows about the column, but there's no view to reference,
                                                      // so we use currentView for lack of anything better
                                } else {
                                  return viewsMap[expr.qualifier]; // joined table has no alias
                                }
                              } else {
                                return viewsMap._; // not a joined table
                              }
                            })();
                            sourceColumn = findViewColumnCaseInsensitive(sourceView.columns, expr);
                          }
                          const fieldName = selection.name.toLowerCase();
                          const revColumnMatch = this.getColumn(selection);
                          const viewColumnMatch: Option<Partial<VQEColumn>> = option(
                            currentView.columns.find((col) => col.fieldName === fieldName)
                          );

                          // only because view column makes position non optional which is bullshit IMO
                          const metadataCol: Option<Partial<VQEColumn>> =
                            [revColumnMatch, viewColumnMatch, sourceColumn].find((c) => c.nonEmpty) || none;
                          const proposedName = metadataCol
                            .map((col) => col.name || fieldName)
                            .getOrElseValue(fieldName);
                          const displayName = dedupeDisplayName(selection, proposedName, inUseDisplayNames);
                          inUseDisplayNames.push(displayName);
                          const returnColumn: VQEColumn = {
                            fieldName: fieldName,
                            description: metadataCol.map((col) => col.description || '').getOrElseValue(''),
                            name: displayName,
                            flags: metadataCol.map((col) => col.flags || []).getOrElseValue([]),
                            format: metadataCol.map((col) => col.format || {}).getOrElseValue({}),
                            position: i + 1,
                            dataTypeName: selection.expr.soql_type || SoQLType.SoQLTextT // this is null when its a null literal, but core will assign this as text
                          };
                          return metadataCol.map((col) => {
                            if (col.width) return {...returnColumn, width: col.width};
                            return returnColumn;
                          }).getOrElseValue(returnColumn);
                        });
                        return Promise.resolve(newColumns);
                      },
                      analysisSuccess => {
                        const selection = selectionWithProvenanceFromQueryAnalysis(analysisSuccess.get);

                        const newColumns = selection.map((selectionItem, position) => {
                          // schemaEntry names are guaranteed to be unique by the backend
                          const fieldName = selectionItem.schemaEntry.name.toLowerCase();
                          const columnFromRevision = this.getColumnNA(selectionItem.schemaEntry);
                          const columnFromAnalysis: Option<ViewColumn> = option(
                            selectionItem.provenance.map(p => p.column).orNull
                          );
                          const metadataColumn: Option<Partial<VQEColumn>> = [columnFromRevision, columnFromAnalysis]
                            .find(c => c.nonEmpty) || none;

                          const returnColumn: VQEColumn = {
                            fieldName,
                            description: metadataColumn.map(c => c.description).getOrElseValue('') ?? '',
                            name: metadataColumn.map(c => c.name).getOrElseValue(fieldName) ?? fieldName,
                            flags: metadataColumn.map(c => c.flags).getOrElseValue([]),
                            format: metadataColumn.map(c => c.format).getOrElseValue({}) ?? {},
                            position: position + 1,
                            dataTypeName: selectionItem.schemaEntry.type || SoQLType.SoQLTextT, // this is null when its a null literal, but core will assign this as text
                            width: metadataColumn.map(c => c.width).orUndefined
                          };

                          return returnColumn;
                        });

                        return Promise.resolve(newColumns);
                      }
                    );
                  },
                  addParameter:
                    (
                      viewId: string,
                      parameter: ClientContextVariableCreate,
                      onSuccess: () => void,
                      onError: (err: any) => void
                    ) =>
                    (dispatch: Dispatch) => {
                      const revisionSeq = this.props.revision.revision_seq;
                      dispatch(
                        Actions.createParameterOnRevision(viewId, parameter, revisionSeq, onSuccess, onError)
                      );
                    },
                  editParameter:
                    (
                      viewId: string,
                      parameter: ClientContextVariableCreate,
                      onSuccess: () => void,
                      onError: (err: any) => void
                    ) =>
                    (dispatch: Dispatch) => {
                      const revisionSeq = this.props.revision.revision_seq;
                      dispatch(
                        Actions.editParameterOnRevision(viewId, parameter, revisionSeq, onSuccess, onError)
                      );
                    },
                  deleteParameter:
                    (
                      viewId: string,
                      parameterName: string,
                      onSuccess: () => void,
                      onError: (err: any) => void
                    ) =>
                    (dispatch: Dispatch) => {
                      const revisionSeq = this.props.revision.revision_seq;
                      dispatch(
                        Actions.deleteParameterOnRevision(
                          viewId,
                          parameterName,
                          revisionSeq,
                          onSuccess,
                          onError
                        )
                      );
                    },
                  replaceAllParameters:
                    (
                      viewId: string,
                      parameters: ClientContextVariableCreate[],
                      considerUndoable = true,
                      onSuccess: () => void,
                      onError: (err: any) => void
                    ) =>
                    (dispatch: Dispatch) => {
                      const revisionSeq = this.props.revision.revision_seq;
                      dispatch(
                        Actions.replaceParameterList(viewId, revisionSeq, parameters, considerUndoable, onSuccess, onError)
                      );
                    },
                  updateUndoRedoHistory: (history: UndoRedoHistory) => {
                    this.props.updateUndoRedoHistory(history);
                  },
                  undoRedoColumnMetadata: (columns: VQEColumn[], updateVQEState: () => void) => {
                    const metadata = {
                      ...this.props.revision.metadata,
                      columns
                    };

                    this.props.updateRevision(this.props.params, { ...this.props.revision, metadata }).then(updateVQEState);
                  }
                }}
              />
            </React.Fragment>
          )
        })}
      </div>
    );
  }
}

interface ExternalProps {
  params: Params;
}
interface StateProps {
  view: View;
  revision: Revision;
  params: Params;
  undoRedoHistory: UndoRedoHistory;
}
interface DispatchProps {
  openFormatColumn: (
    params: Params,
    column: ColumnLike<never>,
    columnUpdated: (updatedColumn: VQEColumn) => void
  ) => void;
  updateRevision: (params: Params, newRev: Revision) => Promise<Revision>;
  deleteRevision: (params: Params) => Promise<void>;
  updateUndoRedoHistory: (undoRedoHistory: UndoRedoHistory) => void;
}
type Props = StateProps & DispatchProps;

const mapStateToProps = (state: AppState, extProps: ExternalProps): StateProps => {
  const view = state.entities.views[extProps.params.fourfour];
  const revision: Revision = currentRevision(state.entities, toNumber(extProps.params.revisionSeq))!;
  const undoRedoHistory = state.ui.vqeUndoRedoHistory;

  return {
    view,
    revision,
    params: extProps.params,
    undoRedoHistory
  };
};

const mergeProps = (
  stateProps: StateProps,
  { dispatch }: { dispatch: Dispatch },
  ownProps: ExternalProps
): Props => ({
  ...stateProps,
  openFormatColumn: (
    params: Params,
    outputColumn: ColumnLike<never>,
    columnUpdated: (updatedColumn: VQEColumn) => void
  ) => {
    const saveText = I18n.t('shared.explore_grid.grid_datasource.footer.apply');
    dispatch(
      ModalActions.showModal('FormatColumn', {
        params,
        outputColumn,
        saveText,
        columnUpdated
      })
    );
  },
  deleteRevision: (params: Params): Promise<void> => dispatch(RevisionActions.deleteRevision(params)),
  updateRevision: (params: Params, newRev: Revision): Promise<Revision> =>
    // ok, i'm not sure how to make Dispatch actually coherent
    dispatch(RevisionActions.updateRevision(newRev, params)) as unknown as Promise<Revision>,
  updateUndoRedoHistory: (undoRedoHistory: UndoRedoHistory) => dispatch(updateUndoRedoHistory(undoRedoHistory))
});

// a null mapDispatchToProps results in passing { dispatch },
// but this case is not covered in the types in Connect.
// @ts-expect-error TS(2769) FIXME: No overload matches this call.
export default connect(mapStateToProps, null, mergeProps)(EditSoQLQuery);
