import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CommonMetadataTable from 'common/components/MetadataTable';
import _ from 'lodash';
import { localizeLink } from 'common/locale';

export class MetadataTable extends Component {
  render() {
    const {
      coreView,
      customMetadataFieldsets,
      disableContactDatasetOwner,
      revision
    } = this.props;

    if (_.isEmpty(coreView)) {
      return null;
    }

    // If the user changes the resource name field in the metadata model, we'll want to
    // show that new resource name instead of the old one on the view.
    const coreViewCopy = _.cloneDeep(coreView);
    const revisionResourceName = _.get(revision, 'metadata.resourceName', '');

    coreViewCopy.resourceName = revisionResourceName;

    return (
      <div id="dsmui-metadata-container">
        <CommonMetadataTable
          localizeLink={localizeLink}
          disableContactDatasetOwner={disableContactDatasetOwner}
          coreView={coreViewCopy}
          customMetadataFieldsets={customMetadataFieldsets} />
      </div>
    );
  }
}

MetadataTable.propTypes = {
  coreView: CommonMetadataTable.propTypes.coreView,
  customMetadataFieldsets: PropTypes.object.isRequired,
  disableContactDatasetOwner: PropTypes.bool,
};

export default MetadataTable;
