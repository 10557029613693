// Vendor Imports
import _ from 'lodash';

// Project Imports
import I18n from 'common/i18n';
import { OPERATOR } from 'common/components/FilterBar/SoqlFilter';
import { SoQLType } from 'common/types/soql';

// Constants
let inputDebounceMs = 700;
let mapSliderDebounceMs = 1000;

const aggregationsScope = 'shared.visualizations.aggregations';
export const AGGREGATION_TYPES = [
  {
    type: 'sum',
    title: I18n.t('sum', { scope: aggregationsScope })
  },
  {
    type: 'avg',
    title: I18n.t('avg', { scope: aggregationsScope })
  },
  {
    type: 'median',
    title: I18n.t('median', { scope: aggregationsScope })
  },
  {
    type: 'max',
    title: I18n.t('max', { scope: aggregationsScope })
  },
  {
    type: 'min',
    title: I18n.t('min', { scope: aggregationsScope })
  }
];

export const HIERARCHY_AGGREGATION_TYPES = [
  {
    type: null,
    title: I18n.t('none', { scope: aggregationsScope })
  },
  {
    type: 'sum',
    title: I18n.t('sum', { scope: aggregationsScope })
  },
  {
    type: 'count',
    title: I18n.t('count', { scope: aggregationsScope })
  },
  {
    type: 'avg',
    title: I18n.t('average', { scope: aggregationsScope })
  },
  {
    type: 'min',
    title: I18n.t('min', { scope: aggregationsScope })
  },
  {
    type: 'max',
    title: I18n.t('max', { scope: aggregationsScope })
  }
];

export const NON_NUMERICAL_HIERARCHY_AGGREGATION_TYPES = [
  {
    type: null,
    title: I18n.t('none', { scope: aggregationsScope })
  },
  {
    type: 'count',
    title: I18n.t('count', { scope: aggregationsScope })
  },
  {
    type: 'min',
    title: I18n.t('min', { scope: aggregationsScope })
  },
  {
    type: 'max',
    title: I18n.t('max', { scope: aggregationsScope })
  }
];

export const GROUPABLE_DATA_TYPES = new Set([
  SoQLType.SoQLNumberT,
  SoQLType.SoQLTextT,
  SoQLType.SoQLBooleanT,
  SoQLType.SoQLBooleanAltT,
  SoQLType.SoQLFixedTimestampT,
  SoQLType.SoQLFixedTimestampAltT,
  SoQLType.SoQLFloatingTimestampT,
  SoQLType.SoQLFloatingTimestampAltT,
  SoQLType.SoQLURLT,
  SoQLType.SoQLJsonT
]);

export const MAX_HIERARCHIES = 10;
export const MAX_HIERARCHY_GROUPINGS = 5;

const baseLayersScope = 'shared.visualizations.base_layers';
export const BASE_LAYERS = [
  {
    showMapboxAttribution: true,
    title: I18n.t('simple_blue', { scope: baseLayersScope }),
    deprecatedValue: 'https://a.tiles.mapbox.com/v3/socrata-apps.3ecc65d4/{z}/{x}/{y}.png',
    legacyMapsTileUrlValue:
      'https://api.mapbox.com/styles/v1/socrata/ckgs33yql0thi19nn0qvl9op8/tiles/{z}/{x}/{y}?access_token={accessToken}',
    value: 'mapbox://styles/socrata/ckgs33yql0thi19nn0qvl9op8'
  },
  {
    showMapboxAttribution: true,
    title: I18n.t('simple_grey', { scope: baseLayersScope }),
    deprecatedValue: 'https://a.tiles.mapbox.com/v3/socrata-apps.ibp0l899/{z}/{x}/{y}.png',
    legacyMapsTileUrlValue:
      'https://api.mapbox.com/styles/v1/socrata/ckgs5ndzb0w4i19pfiw9mmbbu/tiles/{z}/{x}/{y}?access_token={accessToken}',
    value: 'mapbox://styles/socrata/ckgs5ndzb0w4i19pfiw9mmbbu'
  },
  {
    showMapboxAttribution: false,
    title: I18n.t('esri', { scope: baseLayersScope }),
    value: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
  }
];

const basemapStylesScope = 'shared.visualizations.basemap_styles';
export const VECTOR_BASEMAP_STYLES = {
  basic: {
    showMapboxAttribution: true,
    title: I18n.t('basic', { scope: basemapStylesScope }),
    value: 'mapbox://styles/mapbox/basic-v9'
  },
  no_boundaries: {
    showMapboxAttribution: true,
    title: I18n.t('no_boundaries', { scope: basemapStylesScope }),
    value: 'mapbox://styles/socrata/ck8ezilto0my11is3xzjlf8d1'
  },
  streets: {
    showMapboxAttribution: true,
    title: I18n.t('streets', { scope: basemapStylesScope }),
    value: 'mapbox://styles/mapbox/streets-v9'
  },
  bright: {
    showMapboxAttribution: true,
    title: I18n.t('bright', { scope: basemapStylesScope }),
    value: 'mapbox://styles/mapbox/bright-v9'
  },
  light: {
    showMapboxAttribution: true,
    title: I18n.t('light', { scope: basemapStylesScope }),
    value: 'mapbox://styles/mapbox/light-v9'
  },
  dark: {
    showMapboxAttribution: true,
    title: I18n.t('dark', { scope: basemapStylesScope }),
    value: 'mapbox://styles/mapbox/dark-v9'
  },
  satellite: {
    showMapboxAttribution: true,
    title: I18n.t('satellite', { scope: basemapStylesScope }),
    value: 'mapbox://styles/mapbox/satellite-v9'
  }
};

export const BASEMAP_STYLES = BASE_LAYERS.concat([
  VECTOR_BASEMAP_STYLES.basic,
  VECTOR_BASEMAP_STYLES.no_boundaries,
  VECTOR_BASEMAP_STYLES.streets,
  VECTOR_BASEMAP_STYLES.bright,
  VECTOR_BASEMAP_STYLES.light,
  VECTOR_BASEMAP_STYLES.dark,
  VECTOR_BASEMAP_STYLES.satellite
]);

const chartSortingScope = 'shared.visualizations.panes.axis.fields.chart_sorting';
export const CHART_SORTING = [
  {
    title: I18n.t('large_to_small', { scope: chartSortingScope }),
    orderBy: { parameter: 'measure', sort: 'desc' },
    icon: 'icon-sort-desc',
    group: I18n.t('sort_by_value', { scope: chartSortingScope })
  },
  {
    title: I18n.t('small_to_large', { scope: chartSortingScope }),
    orderBy: { parameter: 'measure', sort: 'asc' },
    icon: 'icon-sort-asc',
    group: I18n.t('sort_by_value', { scope: chartSortingScope })
  },
  {
    title: I18n.t('ascending', { scope: chartSortingScope }),
    orderBy: { parameter: 'dimension', sort: 'asc' },
    icon: 'icon-sort-az',
    group: I18n.t('sort_by_label', { scope: chartSortingScope })
  },
  {
    title: I18n.t('descending', { scope: chartSortingScope }),
    orderBy: { parameter: 'dimension', sort: 'desc' },
    icon: 'icon-sort-za',
    group: I18n.t('sort_by_label', { scope: chartSortingScope })
  }
];

export const CALENDAR = {
  DEFAULT_VIF_SERIES_LENGTH: 2,
  START_DATE_SERIES_INDEX: 0,
  END_DATE_SERIES_INDEX: 1,
  EVENT_TITLE_SERIES_INDEX: 2
};
export const CALENDAR_DATE_COLUMN_TYPE = 'calendar_date';
export const DATE_COLUMN_TYPE = 'date';
export const CHECKBOX_COLUMN_TYPE = 'checkbox';
export const COLOR_BY_BUCKETS_COUNT = 20;
export const NUMBER_COLUMN_TYPE = 'number';

export enum BASE_COLOR_PALETTE_NAMES {
  categorical = 'categorical',
  categorical2 = 'categorical2',
  categorical3 = 'categorical3',
  alternate1 = 'alternate1',
  alternate2 = 'alternate2',
  accent = 'accent',
  dark = 'dark'
}

export const COLOR_PALETTE_VALUES = {
  [BASE_COLOR_PALETTE_NAMES.categorical]: [
    '#a6cee3',
    '#5b9ec9',
    '#2d82af',
    '#7eba98',
    '#98d277',
    '#52af43',
    '#6f9e4c',
    '#dc9a88',
    '#f16666',
    '#e42022',
    '#f06c45',
    '#fdbb69',
    '#fe982c',
    '#f78620',
    '#d9a295',
    '#b294c7',
    '#7d54a6',
    '#9e8099',
    '#f0eb99',
    '#dbb466',
    '#969696'
  ],
  [BASE_COLOR_PALETTE_NAMES.categorical2]: [
    '#5b9ec9',
    '#98d277',
    '#f16666',
    '#fdbb69',
    '#b294c7',
    '#f0eb99',
    '#2d82af',
    '#52af43',
    '#dc9a88',
    '#fe982c',
    '#7d54a6',
    '#dbb466',
    '#a6cee3',
    '#6f9e4c',
    '#f06c45',
    '#9e8099',
    '#7eba98',
    '#e42022',
    '#d9a295',
    '#f78620',
    '#969696'
  ],
  [BASE_COLOR_PALETTE_NAMES.categorical3]: [
    '#3f51b5',
    '#e55100',
    '#ffb300',
    '#448aff',
    '#c2185b',
    '#4caf50',
    '#7c4eff',
    '#80cbc4',
    '#f06292',
    '#ff9100',
    '#fdd735',
    '#9ccc65',
    '#546e7a',
    '#9575cd',
    '#a5d6a7',
    '#00897b',
    '#9fa8da',
    '#9c27b0',
    '#558b2f',
    '#004d40',
    '#969696'
  ],
  [BASE_COLOR_PALETTE_NAMES.alternate1]: [
    '#e41a1c',
    '#9e425a',
    '#596a98',
    '#3b87a2',
    '#449b75',
    '#4daf4a',
    '#6b886d',
    '#896191',
    '#ac5782',
    '#d56b41',
    '#ff7f00',
    '#ffb214',
    '#ffe528',
    '#eddd30',
    '#c9992c',
    '#a65628',
    '#c66764',
    '#e678a0',
    '#e485b7',
    '#be8fa8',
    '#969696'
  ],
  [BASE_COLOR_PALETTE_NAMES.alternate2]: [
    '#66c2a5',
    '#9aaf8d',
    '#cf9c76',
    '#f68d67',
    '#cf948c',
    '#a89bb0',
    '#969dca',
    '#b596c7',
    '#d58ec4',
    '#dd95b2',
    '#c6b18b',
    '#afcc64',
    '#b7d84c',
    '#d6d83f',
    '#f6d832',
    '#f8d348',
    '#efcc6b',
    '#e6c58e',
    '#d5be9d',
    '#c4b8a8',
    '#969696'
  ],
  [BASE_COLOR_PALETTE_NAMES.accent]: [
    '#7fc97f',
    '#96bf9e',
    '#adb5bd',
    '#c4afcb',
    '#dbb6af',
    '#f3bd92',
    '#fdcd8a',
    '#fee491',
    '#fefb98',
    '#c0d0a0',
    '#769aa8',
    '#4166ad',
    '#853f9b',
    '#c91889',
    '#e8106e',
    '#d63048',
    '#c45121',
    '#a75d2b',
    '#866148',
    '#666666',
    '#969696'
  ],
  [BASE_COLOR_PALETTE_NAMES.dark]: [
    '#1b9e77',
    '#5d874e',
    '#a07125',
    '#d45f0a',
    '#b16548',
    '#8e6b86',
    '#8068ae',
    '#a850a0',
    '#d03792',
    '#d33b79',
    '#a66753',
    '#79932e',
    '#7fa718',
    '#aca80e',
    '#d9aa04',
    '#d69d08',
    '#bf8b12',
    '#a9781b',
    '#927132',
    '#7c6b4c',
    '#969696'
  ]
};

export const COLOR_PALETTE_VALUES_FOR_MAPS = {
  categorical: (count = 20) => {
    return _.take(
      COLOR_PALETTE_VALUES.categorical,
      _.clamp(count, 1, COLOR_PALETTE_VALUES.categorical.length)
    );
  },
  categorical2: (count = 20) => {
    return _.take(
      COLOR_PALETTE_VALUES.categorical2,
      _.clamp(count, 1, COLOR_PALETTE_VALUES.categorical2.length)
    );
  },
  categorical3: (count = 20) => {
    return _.take(
      COLOR_PALETTE_VALUES.categorical3,
      _.clamp(count, 1, COLOR_PALETTE_VALUES.categorical3.length)
    );
  },
  alternate1: (count = 20) => {
    return _.take(COLOR_PALETTE_VALUES.alternate1, _.clamp(count, 1, COLOR_PALETTE_VALUES.alternate1.length));
  },
  alternate2: (count = 20) => {
    return _.take(COLOR_PALETTE_VALUES.alternate2, _.clamp(count, 1, COLOR_PALETTE_VALUES.alternate2.length));
  },
  accent: (count = 20) => {
    return _.take(COLOR_PALETTE_VALUES.accent, _.clamp(count, 1, COLOR_PALETTE_VALUES.accent.length));
  },
  dark: (count = 20) => {
    return _.take(COLOR_PALETTE_VALUES.dark, _.clamp(count, 1, COLOR_PALETTE_VALUES.dark.length));
  },
  diverging1: (count = 9) => {
    return DIVERGING1_COLOR_BUCKETS[_.clamp(count, 2, 7)];
  },
  diverging2: (count = 9) => {
    return DIVERGING2_COLOR_BUCKETS[_.clamp(count, 2, 7)];
  },
  sequential1: (count = 9) => {
    return SEQUENTIAL1_COLOR_BUCKETS[_.clamp(count, 2, 7)];
  },
  sequential2: (count = 9) => {
    return SEQUENTIAL2_COLOR_BUCKETS[_.clamp(count, 2, 7)];
  }
};

const colorPalettesScope = 'shared.visualizations.color_palettes';
export const COLOR_PALETTES = [
  {
    // This is the Forge color palette, and it should be first.
    title: I18n.t('categorical3', { scope: colorPalettesScope }),
    value: 'categorical3'
  },
  {
    title: I18n.t('categorical', { scope: colorPalettesScope }),
    value: 'categorical'
  },
  {
    title: I18n.t('categorical2', { scope: colorPalettesScope }),
    value: 'categorical2'
  },
  {
    title: I18n.t('alternate1', { scope: colorPalettesScope }),
    value: 'alternate1'
  },
  {
    title: I18n.t('alternate2', { scope: colorPalettesScope }),
    value: 'alternate2'
  },
  {
    title: I18n.t('accent', { scope: colorPalettesScope }),
    value: 'accent'
  },
  {
    title: I18n.t('dark', { scope: colorPalettesScope }),
    value: 'dark'
  }
];

export const SEQUENTIAL_COLOR_PALETTES = [
  {
    title: I18n.t('sequential1', { scope: colorPalettesScope }),
    value: 'sequential1'
  },
  {
    title: I18n.t('sequential2', { scope: colorPalettesScope }),
    value: 'sequential2'
  }
];

export const COLOR_PALETTES_FOR_MAPS = _.concat(COLOR_PALETTES, SEQUENTIAL_COLOR_PALETTES);

export const DIVERGING_COLOR_PALETTES = [
  {
    title: I18n.t('diverging1', { scope: colorPalettesScope }),
    value: 'diverging1'
  },
  {
    title: I18n.t('diverging2', { scope: colorPalettesScope }),
    value: 'diverging2'
  }
];

const colorScalesScope = 'shared.visualizations.color_scales';
export const COLOR_SCALES = [
  {
    title: I18n.t('simple_blue', { scope: colorScalesScope }),
    value: 'simpleBlue',
    scale: ['#c6663d', '#ffffff', '#003747']
  },
  {
    title: I18n.t('simple_grey', { scope: colorScalesScope }),
    value: 'simpleGrey',
    scale: ['#c8c8c8', '#bdbdbd', '#2c2c2c']
  },
  {
    title: I18n.t('red_yellow', { scope: colorScalesScope }),
    value: 'RdOrYl',
    scale: ['#2482bc', '#fdffac', '#ba001e']
  },
  {
    title: I18n.t('green_white_purple', { scope: colorScalesScope }),
    value: 'GrWhPu',
    scale: ['#008932', '#f7f7f7', '#7c2d96']
  }
];

export const COLORS = [
  '#f0f2ff',
  '#eff8fb',
  '#eff8fb',
  '#f7f7f7',
  '#fdeddd',
  '#f6eef7',
  '#feffc8',
  '#fdebe1',
  '#fdffac',
  '#bed7e8',
  '#b5e3e2',
  '#b5cce5',
  '#cccccc',
  '#fac07e',
  '#bfc8e3',
  '#a2deb2',
  '#f8b2b8',
  '#fad04b',
  '#71abd9',
  '#6ac5a3',
  '#8f92c9',
  '#969696',
  '#f98d27',
  '#6da7d2',
  '#4cb6c6',
  '#f45ca1',
  '#f98d27',
  '#3d7ec0',
  '#31a75a',
  '#894baa',
  '#636363',
  '#e25200',
  '#2a919a',
  '#387bbb',
  '#c3008c',
  '#ec3001',
  '#1e489f',
  '#067126',
  '#80007f',
  '#252525',
  '#a33200',
  '#0f6f59',
  '#2d2298',
  '#79007a',
  '#ba001e'
];

export const DIMENSION_TYPES = [
  {
    type: 'blob'
  },
  {
    type: 'date',
    preferredVisualizationTypes: ['barChart', 'columnChart', 'timelineChart', 'comboChart']
  },
  {
    type: 'calendar_date',
    preferredVisualizationTypes: ['barChart', 'columnChart', 'timelineChart', 'comboChart']
  },
  {
    type: 'checkbox'
  },
  {
    type: 'line',
    preferredVisualizationTypes: ['map']
  },
  {
    type: 'multiline',
    preferredVisualizationTypes: ['map']
  },
  {
    type: 'multipoint',
    preferredVisualizationTypes: ['map']
  },
  {
    type: 'multipolygon',
    preferredVisualizationTypes: ['map']
  },
  {
    type: 'money',
    preferredVisualizationTypes: ['barChart', 'columnChart', 'pieChart', 'histogram', 'comboChart']
  },
  {
    type: 'number',
    preferredVisualizationTypes: ['barChart', 'columnChart', 'histogram', 'comboChart', 'scatterChart']
  },
  {
    type: 'point',
    preferredVisualizationTypes: ['featureMap', 'regionMap', 'map']
  },
  {
    type: 'location',
    preferredVisualizationTypes: ['map']
  },
  {
    type: 'polygon',
    preferredVisualizationTypes: ['map']
  },
  {
    type: 'text',
    preferredVisualizationTypes: ['barChart', 'columnChart', 'pieChart', 'comboChart']
  },
  {
    type: 'url',
    preferredVisualizationTypes: ['barChart', 'columnChart', 'pieChart', 'comboChart']
  }
];
export const DEFAULT_ARTICLE_SUPPORT_LINK = 'https://support.socrata.com/hc/en-us/articles/360000627087';
export const DEFAULT_BASEMAP_STYLE = VECTOR_BASEMAP_STYLES.basic.value;
export const DEFAULT_BOOLEAN_VALUE = 'false';
// This is the Forge color palette
export const DEFAULT_COLOR_PALETTE = BASE_COLOR_PALETTE_NAMES.categorical3;
export const DEFAULT_COLUMN_PRECISION = 2;
export const DEFAULT_CHARMS_COUNT = 27;
export const DEFAULT_POINT_AND_LINE_COLOR = '#cccccc';
export const DEFAULT_PRIMARY_COLOR = '#3f51b5';
export const DEFAULT_ZOOM_LEVEL_ROUND_OFF_VALUE = 2;
export const DEFAULT_SCATTER_CHART_COLUMN_VALUE = null;
export const DEFAULT_SECONDARY_COLOR = '#e55100';
export const DEFAULT_SHAPE_FILL_COLOR = '#cccccc';
export const DEFAULT_SHAPE_FILL_OPACITY = 79;
export const DEFAULT_SHAPE_OUTLINE_COLOR = '#f7f7f7';
export const DEFAULT_SHAPE_OUTLINE_WIDTH = 1.5;
// If filter item is in the alignBottomArea, meaning at the bottom most part of the page, then
// show the filter dropdown above the filter item instead of below it.
export const DEFAULT_ALIGN_TOP_AREA_HEIGHT = 450;

export const DIVERGING1_COLOR_BUCKETS = {
  2: ['#a6611a', '#018571'],
  3: ['#d8b365', '#f5f5f5', '#5ab4ac'],
  4: ['#a6611a', '#dfc27d', '#80cdc1', '#018571'],
  5: ['#a6611a', '#dfc27d', '#f5f5f5', '#80cdc1', '#018571'],
  6: ['#8c510a', '#d8b365', '#f6e8c3', '#c7eae5', '#5ab4ac', '#01665e'],
  7: ['#8c510a', '#d8b365', '#f6e8c3', '#f5f5f5', '#c7eae5', '#5ab4ac', '#01665e']
};

export const DIVERGING2_COLOR_BUCKETS = {
  2: ['#d7191c', '#2c7bb6'],
  3: ['#fc8d59', '#ffffbf', '#91bfdb'],
  4: ['#d7191c', '#fdae61', '#abd9e9', '#2c7bb6'],
  5: ['#d7191c', '#fdae61', '#ffffbf', '#abd9e9', '#2c7bb6'],
  6: ['#d73027', '#fc8d59', '#fee090', '#e0f3f8', '#91bfdb', '#4575b4'],
  7: ['#d73027', '#fc8d59', '#fee090', '#ffffbf', '#e0f3f8', '#91bfdb', '#4575b4']
};

// Used to speed up tests.
export const disableInputDebounce = () => {
  inputDebounceMs = 1;
  mapSliderDebounceMs = 1;
};

export const EMPTY_TRANSPARENT_COLOR = 'transparent';
export const EMPTY_BUCKET_VALUE = '__empty_value__';
export const COLORS_WITH_EMPTY_TRANSPARENT_COLOR = [EMPTY_TRANSPARENT_COLOR, '#eff8fb', '#f0f2ff'].concat(
  _.takeRight(COLORS, COLORS.length - 3)
);
export const ERROR_BARS_DEFAULT_BAR_COLOR = '#767676';
export const FILTER_SORTING_TYPES = {
  count: {
    title: 'count'
  },
  alphabetical: {
    title: 'alphabetical'
  }
};

export type FilterSortOption = typeof FILTER_SORTING[number];
export type FilterOrderConfig = FilterSortOption['orderBy'];
export const FILTER_SORTING = [
  {
    title: I18n.t('most_common_to_least_common_value', { scope: chartSortingScope }),
    description: I18n.t('value_most_common_to_least_common', { scope: chartSortingScope }),
    orderBy: { parameter: FILTER_SORTING_TYPES.count.title, sort: 'desc' },
    icon: 'icon-sort-desc',
    group: I18n.t('sort_by_value', { scope: chartSortingScope })
  },
  {
    title: I18n.t('least_common_to_most_common_value', { scope: chartSortingScope }),
    description: I18n.t('value_least_common_to_most_common', { scope: chartSortingScope }),
    orderBy: { parameter: FILTER_SORTING_TYPES.count.title, sort: 'asc' },
    icon: 'icon-sort-asc',
    group: I18n.t('sort_by_value', { scope: chartSortingScope })
  },
  {
    title: I18n.t('a_z_ascending_by_label', { scope: chartSortingScope }),
    description: I18n.t('label_a_z', { scope: chartSortingScope }),
    orderBy: { parameter: FILTER_SORTING_TYPES.alphabetical.title, sort: 'asc' },
    icon: 'icon-sort-az',
    group: I18n.t('sort_by_label', { scope: chartSortingScope })
  },
  {
    title: I18n.t('z_a_descending_by_label', { scope: chartSortingScope }),
    description: I18n.t('label_z_a', { scope: chartSortingScope }),
    orderBy: { parameter: FILTER_SORTING_TYPES.alphabetical.title, sort: 'desc' },
    icon: 'icon-sort-za',
    group: I18n.t('sort_by_label', { scope: chartSortingScope })
  }
];
export const GEO_LOCATION_COLUMN_TYPES = [
  'location',
  'point',
  'multipoint',
  'line',
  'multiline',
  'polygon',
  'multipolygon'
];

export const isCustomColorPalette = (value: string | null) => {
  return value === 'custom' || value === null;
};
export const getInputDebounceMs = () => inputDebounceMs;
export const getMapSliderDebounceMs = () => mapSliderDebounceMs;

export const HIERARCHY_LIMITS = {
  ODP: 5,
  SCGC: 10
};
export const MAXIMUM_COMBO_CHART_MEASURES = 6;
export const MAXIMUM_MAP_LAYERS = 8;
export const MAXIMUM_MEASURES = 12;
export const MEASURE_AXIS_PRECISION_AUTOMATIC = 'automatic';
export const MEASURE_AXIS_PRECISION_CUSTOM = 'custom';
export const MEASURE_AXIS_SCALE_CUSTOM = 'custom';
export const MEASURE_AXIS_SCALE_MIN_TO_MAX = 'min_to_max';
export const MEASURE_AXIS_SCALE_ZERO_TO_MAX = 'zero_to_max';
export const NULL_CATEGORY_VALUE = '';
export const NULL_CHARM_NAME = '';
export const NULL_COLUMN_NAME = 'NULL_COLUMN_NAME';
export const NUMERIC_COLUMN_TYPES = ['number', 'money', 'percent'];
export const PRECISION_AUTOMATIC = 'automatic';
export const PRECISION_CUSTOM = 'custom';
export const NUMBER_CURRENCY_PRECISION = 'currency';
export const NUMBER_FINANCIAL_PRECISION = 'financial';

const scope = 'shared.dataset_management_ui.format_column';
export const NUMBER_PRECISION_STYLES = [
  {
    value: 'standard',
    label: I18n.t('number_format.standard', { scope })
  },
  {
    value: 'percentage',
    label: I18n.t('number_format.percentage', { scope })
  },
  {
    value: NUMBER_CURRENCY_PRECISION,
    label: I18n.t('number_format.currency', { scope })
  },
  {
    value: NUMBER_FINANCIAL_PRECISION,
    label: I18n.t('number_format.financial', { scope })
  }
  // TODO: Need next stories.
  // {
  //   value: 'scientific',
  //   label: I18n.t('number_format.scientific', { scope })
  // },
];

export const POINT_AGGREGATIONS = Object.freeze({
  NONE: 'none',
  HEAT_MAP: 'heat_map',
  REGION_MAP: 'region_map'
});

export const POINT_COLUMN_TYPES = ['location', 'point'];

const quantificationMethodsScope = 'shared.visualizations.panes.map_layers.fields.quantification_methods';
export enum QUANTIFICATION_METHOD_VALUES {
  category = 'category',
  linear = 'linear'
}
export const QUANTIFICATION_METHODS = {
  [QUANTIFICATION_METHOD_VALUES.category]: {
    title: I18n.t(QUANTIFICATION_METHOD_VALUES.category, { scope: quantificationMethodsScope }),
    enableColorByBucketsCount: true,
    value: QUANTIFICATION_METHOD_VALUES.category
  },
  [QUANTIFICATION_METHOD_VALUES.linear]: {
    title: I18n.t(QUANTIFICATION_METHOD_VALUES.linear, { scope: quantificationMethodsScope }),
    enableColorByBucketsCount: false,
    value: QUANTIFICATION_METHOD_VALUES.linear
  }
};

export const RADIUS_UNITS = ['miles'];
const rangeBucketTypesScope = 'shared.visualizations.panes.map_layers.fields.range_bucket_types';
export const RANGE_BUCKET_TYPES = {
  equalInterval: {
    title: I18n.t('linear', { scope: quantificationMethodsScope }),
    value: 'equalInterval'
  },
  jenks: {
    title: I18n.t('jenks', { scope: rangeBucketTypesScope }),
    value: 'jenks'
  }
};

export const REFERENCE_LINES_DEFAULT_LINE_COLOR = '#767676';
export const SCATTER_CHART = {
  DEFAULT_VIF_SERIES_LENGTH: 3,
  COLOR_BY_SERIES_INDEX: 2,
  MAX_PLOT_SIZE: Object.freeze({ MIN: 4, MAX: 40, DEFAULT: 18 }),
  MIN_PLOT_SIZE: Object.freeze({ MIN: 4, MAX: 40, DEFAULT: 10, STEP: 1 }),
  PLOT_SIZE: Object.freeze({ MIN: 4, MAX: 40, DEFAULT: 10, STEP: 1 }),
  RESIZE_BY_SERIES_INDEX: 3,
  X_AXIS_SERIES_INDEX: 0,
  Y_AXIS_SERIES_INDEX: 1
};

export const SEQUENTIAL1_COLOR_BUCKETS = {
  2: ['#e5f5f9', '#006d2c'],
  3: ['#e5f5f9', '#99d8c9', '#2ca25f'],
  4: ['#edf8fb', '#b2e2e2', '#66c2a4', '#238b45'],
  5: ['#edf8fb', '#b2e2e2', '#66c2a4', '#2ca25f', '#006d2c'],
  6: ['#edf8fb', '#ccece6', '#99d8c9', '#66c2a4', '#2ca25f', '#006d2c'],
  7: ['#edf8fb', '#ccece6', '#99d8c9', '#66c2a4', '#41ae76', '#238b45', '#005824']
};

export const SEQUENTIAL2_COLOR_BUCKETS = {
  2: ['#fee8c8', '#b30000'],
  3: ['#fee8c8', '#fdbb84', '#e34a33'],
  4: ['#fef0d9', '#fdcc8a', '#fc8d59', '#d7301f'],
  5: ['#fef0d9', '#fdcc8a', '#fc8d59', '#e34a33', '#b30000'],
  6: ['#fef0d9', '#fdd49e', '#fdbb84', '#fc8d59', '#e34a33', '#b30000'],
  7: ['#fef0d9', '#fdd49e', '#fdbb84', '#fc8d59', '#ef6548', '#d7301f', '#990000']
};

export const SERIES_TYPE_BAR_CHART = 'barChart';
export const SERIES_TYPE_COLUMN_CHART = 'columnChart';
export const SERIES_TYPE_COMBO_CHART = 'comboChart';
export const SERIES_TYPE_COMBO_CHART_COLUMN = 'comboChart.column';
export const SERIES_TYPE_COMBO_CHART_LINE = 'comboChart.line';
export const SERIES_TYPE_FLYOUT = 'flyout';
export const SERIES_TYPE_PIE_CHART = 'pieChart';
export const SERIES_TYPE_TIMELINE_CHART = 'timelineChart';

// Series variants really only apply to comboChart at present.  For example,
// a comboChart series type can be "comboChart.column" or "comboChart.line".
// The series variant is the "column" or "line" part.  The variant tells the
// visualization to render a series of columns or lines.
export const SERIES_VARIANT_COLUMN = 'column';
export const SERIES_VARIANT_LINE = 'line';
export const STEP_VALUES = {
  0: 1,
  1: 0.1,
  2: 0.01,
  3: 0.001,
  4: 0.0001
};

const timelinePrecisionScope = 'shared.visualizations.panes.data.fields.timeline_precision';
export const TIMELINE_PRECISION = [
  {
    title: I18n.t('automatic', { scope: timelinePrecisionScope }),
    value: null
  },
  {
    title: I18n.t('year', { scope: timelinePrecisionScope }),
    value: 'year'
  },
  {
    title: I18n.t('month', { scope: timelinePrecisionScope }),
    value: 'month'
  },
  {
    title: I18n.t('day', { scope: timelinePrecisionScope }),
    value: 'day'
  },
  {
    title: I18n.t('none', { scope: timelinePrecisionScope }),
    value: 'none'
  }
];

export const UPGRADE_TO_NEW_GL_MAPS_SUPPORT_LINK =
  'https://support.socrata.com/hc/en-us/articles/360000626547';

const simplificationScope = 'shared.visualizations.panes.map_layers.fields.simplification_level.options';
const agTextFontStyleScope = 'shared.visualizations.panes.presentation.fields.ag_text_style.options';
const agConditionalTypeScope =
  'shared.visualizations.panes.presentation.fields.ag_column_conditional_type.options';
const agTextStyleOptions = {
  NORMAL: 'normal',
  SEMI_BOLD: 'semi_bold',
  BOLD: 'bold',
  ITALIC: 'italic'
};
export const agConditionalTypeOptions = {
  CONDITION: 'condition',
  LINEAR: 'linear'
};

export const VIF_CONSTANTS = Object.freeze({
  AG_TEXT_FONT_STYLE: {
    OPTIONS: [
      {
        title: I18n.t('normal', { scope: agTextFontStyleScope }),
        value: agTextStyleOptions.NORMAL
      },
      {
        title: I18n.t('semi_bold', { scope: agTextFontStyleScope }),
        value: agTextStyleOptions.SEMI_BOLD
      },
      {
        title: I18n.t('bold', { scope: agTextFontStyleScope }),
        value: agTextStyleOptions.BOLD
      },
      {
        title: I18n.t('italic', { scope: agTextFontStyleScope }),
        value: agTextStyleOptions.ITALIC
      }
    ]
  },
  AG_CONDITIONAL_TYPE: {
    OPTIONS: [
      {
        title: I18n.t('condition', { scope: agConditionalTypeScope }),
        value: agConditionalTypeOptions.CONDITION
      },
      {
        title: I18n.t('linear', { scope: agConditionalTypeScope }),
        value: agConditionalTypeOptions.LINEAR
      }
    ]
  },
  BASEMAP_OPACITY: Object.freeze({ MIN: 0, MAX: 1 }),
  CLUSTER_RADIUS: Object.freeze({ MIN: 20, MAX: 120, DEFAULT: 80, STEP: 1 }),
  CLUSTER_SIZE: Object.freeze({ MIN: 24, MAX: 50, DEFAULT: 40, STEP: 1 }),
  CLUSTERING_ZOOM: Object.freeze({ MIN: 1, MAX: 22, DEFAULT: 9, STEP: 1 }),
  COLOR_BY_BUCKETS_COUNT: Object.freeze({ MIN: 2, MAX: 7, DEFAULT: 5 }),
  CURRENT_MAP_LAYER_INDEX: 0,
  DEFAULT_CALENDAR_TEXT_COLOR: '#ffffff',
  DEFAULT_CALENDAR_BACK_GROUND_COLOR: DEFAULT_PRIMARY_COLOR,
  DEFAULT_LOCK_CALENDAR_VIEW_CONTROL: false,
  DEFAULT_CALENDAR_EVENT_OUTLINE_COLOR: DEFAULT_PRIMARY_COLOR,
  DEFAULT_BOOLEAN_COLUMN_VALUE: false,
  DEFAULT_COLOR_BY_COLUMN_VALUE: null,
  DEFAULT_CAST_NULL_AS_FALSE: false,
  DEFAULT_DISPLAY_DATE: null,
  DEFAULT_SHOW_ANNOTATIONS_IN_LEGEND: true,
  DEFAULT_END_DATE_COLUMN_VALUE: null,
  DEFAULT_EVENT_TITLE_COLUMN_VALUE: null,
  DEFAULT_RESIZE_BY_COLUMN_VALUE: null,
  DEFALUT_SHAPE_FILE_UID_VALUE: null,
  DEFAULT_SHOW_DATASET_SELECTION_VALUE: false,
  DEFAULT_SHOW_DIMENSION_LABEL: true,
  DEFAULT_BOUNDARY_COLOR_BY_COLUMN_VALUE: null,
  DEFAULT_COLOR_LINES_BY_COLUMN_VALUE: null,
  DEFAULT_COLOR_PALETTE_VALUE: null,
  DEFAULT_COLOR_POINTS_BY_COLUMN_VALUE: null,
  DEFAULT_SHOW_DATA_TABLE_CONTROL: true,
  DEFAULT_DATASET_UID: null,
  DEFAULT_DESCRIPTION: null,
  DEFAULT_DIMENSION: null,
  DEFAULT_DIMENSION_GROUPING: null,
  DEFAULT_DIMENSION_GROUPING_COLUMN_VALUE: null,
  DEFAULT_FLYOUT_UNIT: null,
  DEFAULT_GEO_CODER_CONTROL: true,
  DEFAULT_GEO_LOCATION_COLUMN: false,
  DEFAULT_GEO_LOCATION_CONTROL: true,
  DEFAULT_LAYER_TOGGLE_CONTROL: true,
  DEFAULT_LEGEND_POSITION: null,
  DEFAULT_LIMIT_COUNT: null,
  DEFAULT_LOGARITHMIC_SCALE: null,
  DEFAULT_MAP_PRECISION_VALUE: 1,
  DEFAULT_MAP_LEGEND_PRECISION: null,
  DEFAULT_MAP_FLYOUT_PRECISION: null,
  DEFAULT_MAP_FLYOUT_TITLE_VALUE: null,
  DEFAULT_MAP_LAYER_NAME: 'Not Named',
  DEFAULT_MAP_LAYER_PRIMARY_VALUE: false,
  DEFAULT_MAP_LAYER_SOURCE: null,
  DEFAULT_MAP_TYPE_VALUE: null,
  DEFAULT_MAP_ZOOM_LEVEL: Object.freeze({ start: 1, end: 22 }),
  DEFAULT_MEASURE: null,
  DEFAULT_MEASURE_AXIS_MAX_VALUE: null,
  DEFAULT_MEASURE_AXIS_MIN_VALUE: null,
  DEFAULT_MEASURE_AXIS_PRECISION: null,
  DEFAULT_MID_POINT: '',
  DEFAULT_NAVIGATION_CONTROL: true,
  DEFAULT_NEW_TABLE_ACTIONS: [],
  DEFAULT_POINT_AGGREGATION: 'none',
  DEFAULT_PRIMARY_COLOR_VALUE: null,
  DEFAULT_REGION_CODING_SHAPE_FILE_ID_VALUE: null,
  DEFAULT_RESIZE_POINTS_BY_COLUMN_VALUE: null,
  DEFAULT_ROTATION_ANGLE: 90,
  DEFAULT_ROTATION_ANGLE_BAR_CHART: 0,
  DEFAULT_ROW_INSPECTOR_TITLE_VALUE: null,
  DEFAULT_SCATTER_CHART_FLYOUT_SERIES: 2,
  DEFAULT_SECONDARY_AXIS_FOR_COLUMNS: false,
  DEFAULT_SECONDARY_AXIS_FOR_LINES: false,
  DEFAULT_SECONDARY_COLOR_VALUE: null,
  DEFAULT_SECONDARY_MEASURE_AXIS_MAX_VALUE: null,
  DEFAULT_SECONDARY_MEASURE_AXIS_MIN_VALUE: null,
  DEFAULT_SHOW_LEGEND: false,
  DEFAULT_SHOW_LEGEND_FOR_MAP: true,
  DEFAULT_SHOW_LEGEND_OPENED: false,
  DEFAULT_SHOW_LINE_VALUE_LABEL: false,
  DEFAULT_SHOW_MULTIPLE_POINTS_SYMBOL_IN_LEGEND: false,
  DEFAULT_SHOW_NULLS_AS_FALSE: false,
  DEFAULT_SHOW_SLICE_PERCENTS_VALUE: true,
  DEFAULT_SHOW_VALUE_LABEL: false,
  DEFAULT_SHOW_VALUE_LABEL_AS_PERCENT: false,
  DEFAULT_START_DATE_COLUMN_VALUE: null,
  DEFAULT_TITLE: null,
  DEFAULT_VIF_SERIES_LENGTH: 0,
  DEFAULT_VIEW_SOURCE_DATA_LINK: true,
  DEFAULT_VISUALIZATION_TYPE: null,
  DEFAULT_WEIGHT_LINES_BY_COLUMN_VALUE: null,
  DEFAULT_WRAP_DIMENSION_LABELS: false,
  FEATURE_COLOR: '#ff00ff',
  DEFAULT_SHOW_PROCESS_DISCOVERY_ACTION: false,
  DEFAULT_SHOW_EXISTING_ACTION_COLUMN: false,
  DEFAULT_SELECTED_EXISING_COLUMN: null,
  DEFAULT_SELECTED_ACTION_FOR_EXISING_COLUMN: null,
  // Latest version of the Vif
  LATEST_VERSION: 8,

  // Line Map Constants
  LINE_COLOR_OPACITY: Object.freeze({ DEFAULT: 79, MIN: 0, MAX: 100, STEP: 1 }),
  LINE_MAP_MAX_LINE_WEIGHT: Object.freeze({ MIN: 1, MAX: 10, DEFAULT: 10 }),
  LINE_MAP_MIN_LINE_WEIGHT: Object.freeze({ MIN: 1, MAX: 10, DEFAULT: 2 }),
  LINE_WEIGHT: Object.freeze({ MIN: 1, MAX: 10, DEFAULT: 2, STEP: 1 }),
  MIN_STACK_SIZE_TO_SHOW_LABEL: 7,
  MAP_ZOOM_LEVEL: Object.freeze({ MIN: 1, MAX: 22, STEP: 1 }),
  MULTI_POINT_MIN_ZOOM_LEVEL: 0,
  NUMBER_OF_DATA_CLASSES: Object.freeze({ MIN: 2, MAX: 7, DEFAULT: 5 }),
  // Point Map Constants
  POINT_MAP_MAX_POINT_SIZE: Object.freeze({ MIN: 4, MAX: 40, DEFAULT: 18 }),
  POINT_MAP_MIN_POINT_SIZE: Object.freeze({ MIN: 4, MAX: 40, DEFAULT: 10, STEP: 1 }),
  POINT_MAP_POINT_SIZE: Object.freeze({ MIN: 4, MAX: 40, DEFAULT: 10, STEP: 1 }),
  POINT_OPACITY: Object.freeze({ MIN: 0, MAX: 100, DEFAULT: 85, STEP: 5 }),
  POINT_THRESHOLD: Object.freeze({ MIN: 100, MAX: 10000, DEFAULT: 4500, STEP: 100 }),
  SHAPE_FILL_OPACITY: Object.freeze({ MIN: 0, MAX: 100, STEP: 1 }),
  SHAPE_OUTLINE_WIDTH: Object.freeze({ MIN: 0.5, MAX: 8 }),
  SIMPLIFICATION_LEVEL: {
    OPTIONS: [
      {
        title: I18n.t('low', { scope: simplificationScope }),
        value: '1'
      },
      {
        title: I18n.t('medium', { scope: simplificationScope }),
        value: '2'
      },
      {
        title: I18n.t('high', { scope: simplificationScope }),
        value: '3'
      }
    ],
    DEFAULT: '2'
  },
  STACK_RADIUS: Object.freeze({ MIN: 1, MAX: 80, DEFAULT: 1, STEP: 1 }),
  STACK_TEXT_COLOR: '#000000'
});

const chartsScope = 'shared.visualizations.charts';
export const VISUALIZATION_TYPES = [
  {
    type: 'barChart',
    title: I18n.t('bar_chart.title', { scope: chartsScope }),
    icon: 'icon-bar-chart-horz',
    preferredDimensionTypes: ['calendar_date', 'date', 'money', 'number', 'percent', 'text']
  },
  {
    type: 'columnChart',
    title: I18n.t('column_chart.title', { scope: chartsScope }),
    icon: 'icon-bar-chart',
    preferredDimensionTypes: ['calendar_date', 'date', 'money', 'number', 'percent', 'text']
  },
  {
    type: 'pieChart',
    title: I18n.t('pie_chart.title', { scope: chartsScope }),
    icon: 'icon-pie-chart',
    preferredDimensionTypes: ['text']
  },
  {
    type: 'histogram',
    title: I18n.t('histogram.title', { scope: chartsScope }),
    icon: 'icon-distribution',
    preferredDimensionTypes: ['money', 'number']
  },
  {
    type: 'comboChart',
    title: I18n.t('combo_chart.title', { scope: chartsScope }),
    icon: 'icon-combo-chart',
    preferredDimensionTypes: ['calendar_date', 'date']
  },
  {
    type: 'scatterChart',
    title: I18n.t('scatter_chart.title', { scope: chartsScope }),
    icon: 'icon-scatter-plot',
    preferredDimensionTypes: ['number']
  },
  {
    type: 'regionMap',
    title: I18n.t('region_map.title', { scope: chartsScope }),
    icon: 'icon-region',
    preferredDimensionTypes: ['point', 'location']
  },
  {
    type: 'featureMap',
    title: I18n.t('feature_map.title', { scope: chartsScope }),
    icon: 'icon-map',
    preferredDimensionTypes: ['point', 'location']
  },
  {
    type: 'map',
    title: I18n.t('map.title', { scope: chartsScope }),
    icon: 'icon-geo',
    preferredDimensionTypes: GEO_LOCATION_COLUMN_TYPES
  },
  {
    type: 'timelineChart',
    title: I18n.t('timeline_chart.title', { scope: chartsScope }),
    icon: 'icon-line-chart',
    preferredDimensionTypes: ['calendar_date', 'date']
  },
  {
    type: 'calendar',
    title: I18n.t('calendar.title', { scope: chartsScope }),
    icon: 'icon-calendar-alt',
    preferredDimensionTypes: ['calendar_date', 'date']
  },
  {
    type: 'table',
    title: I18n.t('table.title', { scope: chartsScope }),
    icon: 'icon-table',
    preferredDimensionTypes: [] // TODO: EN-43261
  },
  {
    type: 'agTable',
    title: I18n.t('table.title', { scope: chartsScope }),
    icon: 'icon-table',
    preferredDimensionTypes: [] // TODO: EN-43261
  }
];

export const WITH_DIVERGING_COLOR_PALETTES_FOR_MAPS = _.concat(
  COLOR_PALETTES_FOR_MAPS,
  DIVERGING_COLOR_PALETTES
);

const agNumberOperatorScope = 'shared.components.filter_bar.range_filter.operators';

export const AG_GRID_NUMBER_FILTER = {
  options: [
    {
      title: I18n.t(`${agNumberOperatorScope}.equal.title`),
      value: '=',
      symbol: I18n.t(`${agNumberOperatorScope}.equal.symbol`)
    },
    {
      title: I18n.t(`${agNumberOperatorScope}.not_equal.title`),
      value: '!=',
      symbol: I18n.t(`${agNumberOperatorScope}.not_equal.symbol`)
    },
    {
      title: I18n.t(`${agNumberOperatorScope}.less_than.title`),
      value: '<',
      symbol: I18n.t(`${agNumberOperatorScope}.less_than.symbol`)
    },
    {
      title: I18n.t(`${agNumberOperatorScope}.greater_than.title`),
      value: '>',
      symbol: I18n.t(`${agNumberOperatorScope}.greater_than.symbol`)
    },
    {
      title: I18n.t(`${agNumberOperatorScope}.less_than_or_equal.title`),
      value: '<=',
      symbol: I18n.t(`${agNumberOperatorScope}.less_than_or_equal.symbol`)
    },
    {
      title: I18n.t(`${agNumberOperatorScope}.greater_than_or_equal.title`),
      value: '>=',
      symbol: I18n.t(`${agNumberOperatorScope}.greater_than_or_equal.symbol`)
    },
    {
      title: I18n.t(`${agNumberOperatorScope}.range_exclusive`),
      value: 'rangeExclusive'
    },
    {
      title: I18n.t(`${agNumberOperatorScope}.range_inclusive`),
      value: 'rangeInclusive'
    }
  ]
};

const agTextOperatorScope = 'shared.components.filter_bar.text_filter';

export const AG_GRID_TEXT_FILTER = {
  options: [
    {
      title: I18n.t(`${agTextOperatorScope}.is`),
      value: OPERATOR.EQUALS
    },
    {
      title: I18n.t(`${agTextOperatorScope}.is_not`),
      value: OPERATOR.NOT_EQUAL
    },
    {
      title: I18n.t(`${agTextOperatorScope}.starts_with`),
      value: OPERATOR.STARTS_WITH
    },
    {
      title: I18n.t(`${agTextOperatorScope}.contains`),
      value: OPERATOR.CONTAINS
    },
    {
      title: I18n.t(`${agTextOperatorScope}.does_not_contain`),
      value: OPERATOR.DOES_NOT_CONTAIN
    }
  ]
};

const agBooleanOptions = {
  TRUE: 'true',
  FALSE: 'false'
};

const agBooleanOperatorScope = 'shared.visualizations.panes.presentation.fields.ag_condition.boolean_options';
export const AG_GRID_BOOLEAN_FILTER = {
  options: [
    {
      title: I18n.t(`${agBooleanOperatorScope}.true`),
      value: agBooleanOptions.TRUE
    },
    {
      title: I18n.t(`${agBooleanOperatorScope}.false`),
      value: agBooleanOptions.FALSE
    }
  ]
};

export const agAlignType = {
  RIGHT: 'right',
  CENTER: 'center',
  LEFT: 'left'
};

export const AgFilterDataType = {
  NUMBER: 'number',
  TEXT: 'text',
  BOOLEAN: 'checkbox',
  DATE: 'date',
  CALENDAR: 'calendar_date'
};

export const AgGridColumnDataFormat = {
  NUMBER: 'number',
  TEXT: 'text',
  BOOLEAN: 'checkbox',
  DATE: 'date',
  CALENDAR: 'calendar_date'
};

export const VIZ_PANE_CSS_ANIMATION_DELAY = 100;
export const DEFAULT_CURRENCY_FORMAT = 'USD';
interface CurrencyFormat {
  value: string;
  title: string;
}
export const CURRENCY_FORMATS: CurrencyFormat[] = [
  { value: 'USD', title: 'United States, Dollars (USD)' },
  { value: 'EUR', title: 'Eurozone, Euros (EUR)' },
  { value: 'GBP', title: 'Great Britain, Pounds (GBP)' },
  { value: 'RUB', title: 'Russia, Rubles (RUB)' },
  { value: 'CAD', title: 'Canada, Dollars (CAD)' },
  { value: 'AFN', title: 'Afghanistan, Afghanis (AFN)' },
  { value: 'ALL', title: 'Albania, Leke (ALL)' },
  { value: 'ARS', title: 'Argentina, Pesos (ARS)' },
  { value: 'AUD', title: 'Australia, Dollars (AUD)' },
  { value: 'AZN', title: 'Azerbaijan, New Manats (AZN)' },
  { value: 'BSD', title: 'Bahamas, Dollars (BSD)' },
  { value: 'BBD', title: 'Barbados, Dollars (BBD)' },
  { value: 'BYR', title: 'Belarus, Rubles (BYR)' },
  { value: 'BZD', title: 'Belize, Dollars (BZD)' },
  { value: 'BMD', title: 'Bermuda, Dollars (BMD)' },
  { value: 'BOB', title: 'Bolivia, Bolivianos (BOB)' },
  { value: 'BAM', title: 'Bosnia and Herzegovina, Convertible Marka (BAM)' },
  { value: 'BWP', title: 'Botswana, Pulas (BWP)' },
  { value: 'BRL', title: 'Brazil, Real (BRL)' },
  { value: 'BGN', title: 'Bulgaria, Leva (BGN)' },
  { value: 'KHR', title: 'Cambodia, Riels (KHR)' },
  { value: 'CLP', title: 'Chile, Pesos (CLP)' },
  { value: 'CNY', title: 'China, Yuan Renminbi (CNY)' },
  { value: 'COP', title: 'Colombia, Pesos (COP)' },
  { value: 'CRC', title: 'Costa Rica, Colones (CRC)' },
  { value: 'HRK', title: 'Croatia, Kuna (HRK)' },
  { value: 'CZK', title: 'Czech Republic, Koruny (CZK)' },
  { value: 'DKK', title: 'Denmark, Kroner (DKK)' },
  { value: 'DOP', title: 'Dominican Republic, Pesos (DOP)' },
  { value: 'EGP', title: 'Egypt, Pounds (EGP)' },
  { value: 'EEK', title: 'Estonia, Krooni (EEK)' },
  { value: 'FJD', title: 'Fiji, Dollars (FJD)' },
  { value: 'GHC', title: 'Ghana, Cedis (GHC)' },
  { value: 'GTQ', title: 'Guatemala, Quetzales (GTQ)' },
  { value: 'GYD', title: 'Guyana, Dollars (GYD)' },
  { value: 'HKD', title: 'Hong Kong, Dollars (HDK)' },
  { value: 'HNL', title: 'Honduras, Lempiras (HNL)' },
  { value: 'HUF', title: 'Hungary, Forint (HUF)' },
  { value: 'ISK', title: 'Iceland, Kronur (ISK)' },
  { value: 'INR', title: 'India, Rupees (INR)' },
  { value: 'IDR', title: 'Indonesia, Rupiahs (IDR)' },
  { value: 'IRR', title: 'Iran, Rials (IRR)' },
  { value: 'ILS', title: 'Israel, New Shekels (ILS)' },
  { value: 'JMD', title: 'Jamaica, Dollars (JMD)' },
  { value: 'JPY', title: 'Japanese Yen (JPY)' },
  { value: 'KZT', title: 'Kazakhstan, Tenge (KZT)' },
  { value: 'KES', title: 'Kenya, Shilling (KES)' },
  { value: 'KRW', title: 'Korea, Won (KRW)' },
  { value: 'KGS', title: 'Kyrgyzstan, Soms (KGS)' },
  { value: 'LAK', title: 'Laos, Kips (LAK)' },
  { value: 'LVL', title: 'Latvia, Lati (LVL)' },
  { value: 'LBP', title: 'Lebanon, Pounds (LBP)' },
  { value: 'LRD', title: 'Liberia, Dollars (LRD)' },
  { value: 'LTL', title: 'Lithuania, Litai (LTL)' },
  { value: 'MKD', title: 'Macedonia, Denars (MKD)' },
  { value: 'MYR', title: 'Malaysia, Ringgits (MYR)' },
  { value: 'MXN', title: 'Mexico, Pesos (MXN)' },
  { value: 'MNT', title: 'Mongolia, Tugriks (MNT)' },
  { value: 'MZN', title: 'Mozambique, Meticais (MZN)' },
  { value: 'NAD', title: 'Namibia, Dollars (NAD)' },
  { value: 'NPR', title: 'Nepal, Nepal Rupees (NPR)' },
  { value: 'NZD', title: 'New Zealand, Dollar (NZD)' },
  { value: 'NIO', title: 'Nicaragua, Cordobas (NIO)' },
  { value: 'NGN', title: 'Nigeria, Nairas (NGN)' },
  { value: 'NOK', title: 'Norway, Krone (NOK)' },
  { value: 'OMR', title: 'Oman, Rials (OMR)' },
  { value: 'PKR', title: 'Pakistan, Rupees (PKR)' },
  { value: 'PYG', title: 'Paraguay, Guarani (PYG)' },
  { value: 'PEN', title: 'Peru, Nuevos Soles (PEN)' },
  { value: 'PHP', title: 'Philippines, Pesos (PHP)' },
  { value: 'PLN', title: 'Poland, Klotych (PLN)' },
  { value: 'QAR', title: 'Qatar, Rials (QAR)' },
  { value: 'RON', title: 'Romania, New Lei (RON)' },
  { value: 'SAR', title: 'Saudi Arabia, Riyals (SAR)' },
  { value: 'RSD', title: 'Serbia, Dinars (RSD)' },
  { value: 'SGD', title: 'Singapore, Dollars (SGD)' },
  { value: 'SOS', title: 'Somalia, Shillings (SOS)' },
  { value: 'ZAR', title: 'South Africa, Rand (ZAR)' },
  { value: 'LKR', title: 'Sri Lanka, Rupees (LKR)' },
  { value: 'SEK', title: 'Sweden, Kronor (SEK)' },
  { value: 'CHF', title: 'Swiss, Francs (CHF)' },
  { value: 'SYP', title: 'Syria, Pounds (SYP)' },
  { value: 'TWD', title: 'Taiwan, New Dollars (TWD)' },
  { value: 'THB', title: 'Thailand, Baht (THB)' },
  { value: 'TRY', title: 'Turkey, New Lira (TRY)' },
  { value: 'UAH', title: 'Ukraine, Hryvnia (UAH)' },
  { value: 'UYU', title: 'Uruguay, Pesos (UYU)' },
  { value: 'UZS', title: 'Uzbekistan, Sums (UZS)' },
  { value: 'VEF', title: 'Venezuela, Bolivares Fuertes (VEF)' },
  { value: 'VND', title: 'Vietnam, Dong (VND)' },
  { value: 'YER', title: 'Yemen, Rials (YER)' }
];

export const presentationPaneScope = 'shared.visualizations.panes.presentation';
export const DEFAULT_TABLE_HEADER_TEXT_COLOR = '#595959';
export const DEFAULT_TABLE_HEADER_BACKGROUND_COLOR = '#fff';
